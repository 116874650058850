import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Modal } from '@material-ui/core';
import { navigate } from 'gatsby';
import { MdClear } from 'react-icons/md';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 2,
  pb: 3,
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  padding: 30,
  outline: 0,
  outlineStyle: 'none'
};

const ModalConfirmation = ({ t, visible, dispatch }) => {
  const onClose = () => {
    dispatch({ visible: false });
    navigate('/reservation/');
  };

  return (
    <Modal
      disableEscapeKeyDown
      open={visible}
      onClose={() => onClose()}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <div style={{ ...style }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <h2>{t('group.confirm.success')}</h2>
          <IconButton onClick={onClose}>
            <MdClear />
          </IconButton>
        </div>

        <p>{t('group.confirm.success-text')}</p>
      </div>
    </Modal>
  );
};

export default ModalConfirmation;

ModalConfirmation.propTypes = {
  t: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  dispatch: PropTypes.func.isRequired
};

ModalConfirmation.defaultProps = {
  visible: undefined
};
