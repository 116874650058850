import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { withTrans } from '../i18n/withTrans';
import { Grid, Snackbar } from '@material-ui/core';
import ContactGroupForm from '../components/ReservationGroup/ContactGroupForm';
import Layout from '../components/Layout/Layout';
import { Helmet } from 'react-helmet';
import Favicon from '../assets/images/favicon-manatour-16x16.png';
import calendarImg from '../assets/images/calendar_bg.jpg';
import GroupInfoForm from '../components/ReservationGroup/GroupInfoForm';
import useAuthContext from '../contexts/AuthContext';
import { navigate } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ModalConfirmation from '../components/ReservationGroup/ModalConfirmation';
import { Alert } from '@material-ui/lab';

function reducer(state, action) {
  return { ...state, ...action };
}

const ReservationGroup = ({ t }) => {
  const { dispatchAPI } = useAuthContext();
  const [state, dispatch] = useReducer(reducer, {
    contact: {},
    group: {},
    visible: false,
    newsletter: false
  });

  const onCancel = () => {
    navigate('/reservation/');
  };

  const handleClose = () => {
    dispatch({ openValErrorsSnack: false });
  };

  const sendEmail = async () => {
    try {
      await dispatchAPI('POST', {
        url: `/orders/send_email_group`,
        body: state
      });
    } catch (e) {
      if (e.response) console.error(e.response.status);
    }
  };

  const validateForm = async () => {
    const errors = [];
    if (
      !state.contact.prenom ||
      !state.contact.nom ||
      !state.contact.email ||
      state.errors_validation.includes('email_format') ||
      state.errors_validation.includes('phone_format') ||
      !state.captchaToken
    ) {
      errors.push('contact');
      dispatch({ errors_validation: errors, openValErrorsSnack: true });
    } else {
      dispatch({ errors_validation: [], openValErrorsSnack: false });
    }
    if (!errors.length) {
      await sendEmail();
      dispatch({ visible: true });
    }
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LfU8eAcAAAAAE53fE-ZeWrGKel7iX2Xls-4AOfN">
      <Layout pageName="reservation_group_page">
        <Helmet>
          <title>{t('helmet.reservation_page')}</title>
          <link
            rel="icon"
            type="image/png"
            href={Favicon}
            sizes="16x16"
            className="favicon"
          />
        </Helmet>
        <h2>{t('reservationGroupPage.page-title')}</h2>
        <div className="group-form-wrapper">
          {(state.errors_validation || []).length !== 0 && (
            <Snackbar
              open={state.openValErrorsSnack}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert severity="error">{t('errors.fieldsIncomplete')}</Alert>
            </Snackbar>
          )}
          <ModalConfirmation
            t={t}
            visible={state.visible}
            dispatch={dispatch}
          />
          <Grid
            container
            style={{
              backgroundImage: `url(${calendarImg})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover'
            }}
          >
            <Grid item xs={6}>
              <p>
                {t('reservationGroupPage.title')}
                <span>{t('reservationGroupPage.contact')}</span>
              </p>
              <ContactGroupForm state={state} dispatch={dispatch} t={t} />
            </Grid>
            <Grid item xs={6}>
              <p>
                {t('reservationGroupPage.title')}
                <span>{t('reservationGroupPage.group')}</span>
              </p>
              <GroupInfoForm state={state} dispatch={dispatch} t={t} />
            </Grid>
          </Grid>
          <div style={{ float: 'right', margin: '12px 0 8px 0' }}>
            <button
              onClick={() => onCancel()}
              className="btn-secondary"
              style={{ marginRight: 10 }}
            >
              {t('buttons.back')}
            </button>
            <button className="btn-secondary" onClick={() => validateForm()}>
              {t('buttons.send')}
            </button>
          </div>
        </div>
      </Layout>
    </GoogleReCaptchaProvider>
  );
};

export default withTrans(ReservationGroup);

ReservationGroup.propTypes = {
  t: PropTypes.func.isRequired
};
